import type {
  EditorScriptFlowAPI,
  FlowEditorSDK,
  TFunction,
  WidgetBuilder,
} from '@wix/yoshi-flow-editor';
import type { GetWidgetManifestFn } from '@wix/yoshi-flow-editor/blocks';
import { HELP_IDS, PANEL_IDS } from '../../appConsts/consts';
import { disableElementsSelection } from 'root/editor/editor.utils';
import { ITEM_MODAL_COMPONENT_IDS } from 'root/appConsts/blocksIds';

export const getWidgetManifest: GetWidgetManifestFn = (
  widgetBuilder: WidgetBuilder,
  _: FlowEditorSDK,
  flowAPI: EditorScriptFlowAPI
) => {
  widgetBuilder.set({
    displayName: 'Item Details',
    nickname: 'itemModalWidget',
  });
  const t = flowAPI.translations.t as TFunction;

  widgetBuilder
    .gfpp()
    .set('mainAction1', { label: t('itemModal.gfpp.manageMenus'), actionId: PANEL_IDS.manageMenus })
    .set('mainAction2', { behavior: 'HIDE' })
    .set('add', { actionId: PANEL_IDS.itemModalElements })
    .set('pinMode', { behavior: 'HIDE' })
    .set('settings', { behavior: 'HIDE' })
    .set('design', { behavior: 'HIDE' })
    .set('animation', { behavior: 'HIDE' })
    .set('help', { id: HELP_IDS.ITEM_MODAL });

  widgetBuilder.behavior().set({
    rotatable: false,
    canReparent: false,
    duplicatable: false,
    preventHide: true,
    removable: false,
    resizable: false,
    toggleShowOnAllPagesEnabled: false,
  });

  const DISABLED_ELEMENTS = [
    ITEM_MODAL_COMPONENT_IDS.wrapper,
    ITEM_MODAL_COMPONENT_IDS.proGallery,
    ITEM_MODAL_COMPONENT_IDS.subTitle,
    ITEM_MODAL_COMPONENT_IDS.labelsContainer,
    ITEM_MODAL_COMPONENT_IDS.addToCart,
  ];

  disableElementsSelection(widgetBuilder, DISABLED_ELEMENTS);
};

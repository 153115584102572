import type { ComponentRef, EditorSDK } from '@wix/platform-editor-sdk';
import type { BehaviorObject } from '@wix/document-services-types';
import type {
  ConnectedComponentsBuilder,
  FlowEditorSDK,
  TFunction,
  WidgetBuilder,
} from '@wix/yoshi-flow-editor';
import type { IBIReporterService } from 'root/services/biReporterService';
import { getRole, setWidgetDesignTabs } from 'root/utils/utils';
import type { WidgetDesignTabsArray } from 'root/types/widgets';

const BI_SUBJECT = 'OLO Installation Utils';

const getIsAppInstalled = async (editorSDK: FlowEditorSDK, appDefId: string): Promise<boolean> => {
  return editorSDK.document.application.isApplicationInstalled('_token', {
    appDefinitionId: appDefId,
  });
};

const installApp = async (
  editorSDK: FlowEditorSDK,
  appDefId: string
): Promise<{ instanceId: string }> => {
  return editorSDK.document.application.add('_token', {
    appDefinitionId: appDefId,
  });
};

export const installAppIfMissing = async (
  editorSDK: FlowEditorSDK,
  appDefId: string,
  biReporterService: IBIReporterService
) => {
  try {
    const isAppInstalled = await getIsAppInstalled(editorSDK, appDefId);

    biReporterService?.reportOloGenericDebugBiEvent({
      subjectType: BI_SUBJECT,
      value: {
        appDefId,
        isAppInstalled,
        msid: await editorSDK?.info.getMetaSiteId('_token'),
      },
    });

    if (!isAppInstalled) {
      // eslint-disable-next-line no-console
      console.log('Online orders - Installing app:', appDefId);
      await installApp(editorSDK, appDefId);
      biReporterService?.reportOloGenericDebugBiEvent({
        subjectType: `app installed: ${appDefId}`,
        value: {
          appDefId,
          msid: await editorSDK?.info.getMetaSiteId('_token'),
        },
      });
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    // [TODO] - send Sentry error here
    // eslint-disable-next-line no-console
    console.error('Online orders - issue while installing app', appDefId, e.message);
  }
};

const getPageRef = (editorSDK: FlowEditorSDK, tpaPageId: string): Promise<ComponentRef> => {
  return editorSDK.tpa.getPageRefByTPAPageId('_token', {
    tpaPageId,
  });
};

export const navigateToPage = async (
  editorSDK: FlowEditorSDK,
  tpaPageId: string
): Promise<void> => {
  const pageRef = await getPageRef(editorSDK, tpaPageId);
  return editorSDK.document.pages.navigateTo('_token', { pageRef });
};

export const disablePopupsAutoOpen = async (editorSDK: FlowEditorSDK) => {
  const mainPageRef: ComponentRef = await editorSDK.pages.getPrimary('_token');
  const behaviors = (await editorSDK.components.behaviors.get('_token', {
    componentRef: mainPageRef,
  })) as BehaviorObject[];

  const autoOpenBehaviors = behaviors?.filter(
    (behavior) => behavior.action.name === 'load' && behavior.behavior.name === 'openPopup'
  );

  autoOpenBehaviors?.forEach(async (behavior) => {
    // eslint-disable-next-line no-console
    console.log('Online orders - removing auto open behavior: ', behavior);

    await editorSDK.components.behaviors.remove('_token', {
      componentRef: mainPageRef,
      // @ts-expect-error object type is valid here - Document Management also accepts it: https://github.com/wix-private/document-management/blob/340ef4a8c29ef22503fd3303fa4e60dd775c4362/document-services-implementation/src/actionsAndBehaviors/actionsAndBehaviors.ts#L334
      behaviorName: behavior,
    });
  });
};

export const showInstallationProgressBar = async (
  editorSDK: FlowEditorSDK,
  t: TFunction,
  isResponsive: boolean
): Promise<void> => {
  if (!isResponsive) {
    return editorSDK.editor.openProgressBar('_token', {
      title: t('app.installation.progressBar.title'),
      totalSteps: 4,
      currentStep: 0,
    });
  }
};

export const hideInstallationProgressBar = async (
  editorSDK: FlowEditorSDK,
  isResponsive: boolean
): Promise<void> => {
  if (!isResponsive) {
    return editorSDK.editor.closeProgressBar('_token', {});
  }
};

export const progressBarMoveToStepNumber = async (
  editorSDK: FlowEditorSDK,
  t: TFunction,
  nextStep: number,
  isResponsive: boolean
): Promise<void> => {
  if (!isResponsive) {
    const stepTitle = t(`app.installation.progressBar.step-${nextStep}`);
    return editorSDK.editor.updateProgressBar('_token', {
      currentStep: nextStep,
      stepTitle,
    });
  }
};

async function findAsyncSequential<T>(
  array: T[],
  predicate: (t: T) => Promise<boolean>
): Promise<T | undefined> {
  for (const t of array) {
    if (await predicate(t)) {
      return t;
    }
  }
  return undefined;
}

export const getComponentByTitle = async (editorSDK: EditorSDK, title: string) => {
  const components = await editorSDK.components.getAllComponents('token');
  const comp = await findAsyncSequential<ComponentRef>(components, async (componentRef) => {
    const compData = (await editorSDK.components.data.get('token', { componentRef })) as {
      title: string;
    };
    return compData?.title === title;
  });
  return comp;
};

export const updateWidgetLayout = async (editorSDK: EditorSDK, componentRef: ComponentRef) => {
  const [container] = await editorSDK.components.getChildren('token', { componentRef });
  const [widget] = await editorSDK.components.getChildren('token', {
    componentRef: container,
  });

  widget &&
    (await editorSDK.components.layout.update('token', {
      componentRef: widget,
      layout: { fixedPosition: false },
    }));
};

export const disableElementSelection = (
  componentBuilder: ConnectedComponentsBuilder,
  hideFromHierarchy = true
) => {
  componentBuilder.behavior().set({ closed: { hideFromHierarchy, selectable: false } });
};

export const disableElementsSelection = (
  widgetBuilder: WidgetBuilder,
  components: string[],
  hideFromHierarchy = true
) => {
  components.forEach((componentId) => {
    widgetBuilder.configureConnectedComponents(getRole(componentId), (componentBuilder) => {
      disableElementSelection(componentBuilder, hideFromHierarchy);
    });
  });
};

export const configureWidgetDesign = ({
  widgetBuilder,
  title,
  tabs,
  t,
}: {
  widgetBuilder: WidgetBuilder | ConnectedComponentsBuilder;
  title: string;
  tabs: WidgetDesignTabsArray;
  t: TFunction;
}) => {
  widgetBuilder.configureWidgetDesign((widgetDesignBuilder) => {
    widgetDesignBuilder.set({
      title,
    });
    const widgetDesignTabsBuilder = widgetDesignBuilder.tabs();
    setWidgetDesignTabs(widgetDesignTabsBuilder, tabs, t);
  });
};

export const isMobileViewport = async (editorSDK: EditorSDK) => {
  const editorMode = await editorSDK.info.getCurrentViewport();
  return editorMode.type === 'MOBILE';
};

import { EcomComponent, withEcomPlatformData } from '@wix/ecom-platform-sdk';
import { withMembersArea } from '@wix/members-area-integration-kit';
import { APP_DEF_IDS } from '@wix/restaurants-consts';
import { editorReadyImpl } from './editor/editorReady';
import { exportsImpl } from './editor/exports';
import { createAppManifest } from './editor/appManifest';
import { MEMBERS_AREA_DEFAULT_APPS } from './appConsts/consts';

const platformApp = withMembersArea(
  withEcomPlatformData(
    {
      editorReady: editorReadyImpl,
      getAppManifest: createAppManifest,
      exports: exportsImpl,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } as any,
    {
      ecomComponents: {
        [EcomComponent.CHECKOUT]: {
          isInstalled: true,
        },
        [EcomComponent.THANK_YOU_PAGE]: {
          isInstalled: true,
        },
        [EcomComponent.CART]: {
          isInstalled: true,
        },
        [EcomComponent.CART_ICON]: {
          isInstalled: true,
        },
      },
      managingAppId: APP_DEF_IDS.restaurants,
    }
  ),
  {
    membersAreaApps: MEMBERS_AREA_DEFAULT_APPS,
  }
);

export const editorReady = platformApp.editorReady;
export const getAppManifest = platformApp.getAppManifest;
export const exports = platformApp.exports;

import type { GetWidgetManifestFn } from '@wix/yoshi-flow-editor/blocks';
import type {
  WidgetBuilder,
  FlowEditorSDK,
  EditorScriptFlowAPI,
  TFunction,
} from '@wix/yoshi-flow-editor';
import {
  DISHES_WIDGET_COMPONENT_IDS,
  NON_SELECTABLE_DISHES_ELEMENTS_IDS,
} from 'root/appConsts/blocksIds';
import { PANEL_IDS } from 'root/appConsts/consts';
import { DISHES_DESIGN_PANEL_TABS } from 'root/appConsts/designPanelTabs';
import { configureWidgetDesign, disableElementsSelection } from 'root/editor/editor.utils';
import { getRole } from 'root/utils/utils';

export const getWidgetManifest: GetWidgetManifestFn = (
  widgetBuilder: WidgetBuilder,
  editorSDK: FlowEditorSDK,
  flowAPI: EditorScriptFlowAPI
) => {
  const t = flowAPI.translations.t as TFunction;

  widgetBuilder.behavior().set({ removable: false, preventHide: true });

  widgetBuilder.set({
    displayName: 'Widget Dishes',
    nickname: 'DishesWidget',
  });

  disableElementsSelection(widgetBuilder, NON_SELECTABLE_DISHES_ELEMENTS_IDS);

  widgetBuilder
    .gfpp()
    .set('mainAction1', {
      label: t('dishes.gfpp.main'),
      actionId: PANEL_IDS.manageMenus,
    })
    .set('layout', { actionId: PANEL_IDS.itemLayout })
    .set('add', { actionId: PANEL_IDS.dishesElementsPanel })
    .set('design', { behavior: 'DEFAULT' })
    .set('mainAction2', { behavior: 'HIDE' })
    .set('animation', { behavior: 'HIDE' })
    .set('settings', { behavior: 'HIDE' })
    .set('connect', { behavior: 'HIDE' });

  widgetBuilder.configureConnectedComponents(
    getRole(DISHES_WIDGET_COMPONENT_IDS.dishContainer),
    (componentBuilder) => {
      componentBuilder.behavior().set({ preventHide: true });
    }
  );

  configureWidgetDesign({
    widgetBuilder,
    title: t('design-panel.dishes.title'),
    tabs: DISHES_DESIGN_PANEL_TABS,
    t,
  });
};

import type { EditorSDK, ExportsFn } from '@wix/platform-editor-sdk';
import type { FlowEditorSDK } from '@wix/yoshi-flow-editor';
import { APP_DEF_IDS } from '@wix/restaurants-consts';
import { navigateToPage } from './editor.utils';
import { PAGE_DATA } from '../appConsts/consts';

export const exportsImpl: ExportsFn = (editorSDK: EditorSDK, { biData }) => ({
  editor: {
    async appInstalled({ appDefinitionId }) {
      if (appDefinitionId === APP_DEF_IDS.orders) {
        // eslint-disable-next-line no-console
        console.log(
          'Online ordering - dependecy app was installed. appDefinitionId:',
          appDefinitionId,
          'navigating to the online ordering page'
        );
        return navigateToPage(editorSDK as FlowEditorSDK, PAGE_DATA.pageId);
      }
    },
  },
});

import type {
  EditorReadyFn,
  TFunction,
  FlowEditorSDK,
  PlatformControllerFlowAPI,
} from '@wix/yoshi-flow-editor';
import type { ComponentRef, PageRef } from '@wix/platform-editor-sdk';
import { EditorType } from '@wix/platform-editor-sdk';
import { APP_DEF_IDS } from '@wix/restaurants-consts';
import type { VisitorLogger } from '@wix/yoshi-flow-editor/external-types/bi';
import {
  DISH_ITEM_MODAL_TITLE,
  DISH_ITEM_PRESETS,
  DISPATCH_MODAL_APP_DEF_ID,
  DISPATCH_MODAL_PRESETS,
  DISPATCH_MODAL_TITLE,
  ITEM_MODAL_APP_DEF_ID,
  PAGE_DATA,
  PANEL_IDS,
  PANEL_NAMES,
  LIGHTBOX_IDS,
  ERROR_MODAL_APP_DEF_ID,
  ERROR_MODAL_TITLE,
  ERROR_MODAL_PRESETS,
  ERROR_MODAL_WIDTH,
} from '../appConsts/consts';
import { createAppPage } from '../utils/createPage';
import {
  installAppIfMissing,
  disablePopupsAutoOpen,
  navigateToPage,
  showInstallationProgressBar,
  progressBarMoveToStepNumber,
  hideInstallationProgressBar,
  getComponentByTitle,
  updateWidgetLayout,
  isMobileViewport,
} from './editor.utils';
import {
  openDishesElementsPanel,
  openHeaderElementsPanel,
  openItemModalElementsPanel,
  openOloElementsPanel,
} from '../panels/elementsPanel/elementsActions';
import { PanelsApiFactory } from '@wix/blocks-widget-services/panels';
import { addWidgetToLightbox, installLightbox } from './lightbox';
import { FedopsLogger } from '../utils/monitoring/FedopsLogger';
import {
  openManageMenuDashboardPanel,
  openOrdersSettingsDashboardPanel,
} from '../utils/openDashboardPanels';
import type { IBIReporterService } from 'root/services/biReporterService';
import { BIReporterService } from 'root/services/biReporterService';

const BI_SUBJECT = 'OLO Installation';

const installDishItemLightbox = async (
  editorSDK: FlowEditorSDK,
  appDefId: string,
  isResponsive: boolean,
  biReporterService: IBIReporterService
) => {
  const lightboxRef = await installLightbox(
    editorSDK,
    appDefId,
    ITEM_MODAL_APP_DEF_ID,
    DISH_ITEM_MODAL_TITLE,
    LIGHTBOX_IDS.itemModal,
    isResponsive,
    biReporterService
  );
  return addWidgetToLightbox(
    editorSDK,
    lightboxRef,
    ITEM_MODAL_APP_DEF_ID,
    DISH_ITEM_PRESETS,
    isResponsive,
    biReporterService
  );
};

const installDispatchLightbox = async (
  editorSDK: FlowEditorSDK,
  appDefId: string,
  isResponsive: boolean,
  biReporterService: IBIReporterService
) => {
  const lightboxRef = await installLightbox(
    editorSDK,
    appDefId,
    DISPATCH_MODAL_APP_DEF_ID,
    DISPATCH_MODAL_TITLE,
    LIGHTBOX_IDS.dispatchModal,
    isResponsive,
    biReporterService
  );
  return addWidgetToLightbox(
    editorSDK,
    lightboxRef,
    DISPATCH_MODAL_APP_DEF_ID,
    DISPATCH_MODAL_PRESETS,
    isResponsive,
    biReporterService
  );
};

const installErrorLightbox = async (
  editorSDK: FlowEditorSDK,
  appDefId: string,
  isResponsive: boolean,
  biReporterService: IBIReporterService
) => {
  const lightboxRef = await installLightbox(
    editorSDK,
    appDefId,
    ERROR_MODAL_APP_DEF_ID,
    ERROR_MODAL_TITLE,
    LIGHTBOX_IDS.errorModal,
    isResponsive,
    biReporterService,
    ERROR_MODAL_WIDTH
  );
  return addWidgetToLightbox(
    editorSDK,
    lightboxRef,
    ERROR_MODAL_APP_DEF_ID,
    ERROR_MODAL_PRESETS,
    isResponsive,
    biReporterService,
    ERROR_MODAL_WIDTH
  );
};

const installLightboxes = async (
  editorSDK: FlowEditorSDK,
  appDefId: string,
  isResponsive: boolean,
  biReporterService: IBIReporterService
) => {
  try {
    await installDishItemLightbox(editorSDK, appDefId, isResponsive, biReporterService);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Online orders - installDishItemLightbox error: ', e);
  }
  try {
    await installDispatchLightbox(editorSDK, appDefId, isResponsive, biReporterService);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Online orders - installDispatchLightbox error: ', e);
  }
  try {
    await installErrorLightbox(editorSDK, appDefId, isResponsive, biReporterService);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Online orders - installErrorLightbox error: ', e);
  }
  return disablePopupsAutoOpen(editorSDK);
};

const setOrdersPageState = async (editorSDK: FlowEditorSDK) => {
  const applicationPages = await editorSDK.document.pages.data.getAll('');
  const ordersPage = applicationPages.find((pageData) => pageData.tpaPageId === PAGE_DATA.pageId);

  if (ordersPage) {
    editorSDK.document.pages.setState('', {
      state: {
        [ordersPage.tpaPageId!]: [{ id: ordersPage.id! }] as PageRef[],
      },
    });
  }
};

export const editorReadyImpl: EditorReadyFn = async (editorSDK, appDefId, options, flowAPI) => {
  const { environment } = flowAPI;
  const msid = await editorSDK?.info.getMetaSiteId('_token');
  const bi = options.essentials.biLoggerFactory().logger() as unknown as VisitorLogger;
  const biReporterService = BIReporterService({
    biLogger: bi,
    editorSubType: options.origin.subType,
    environment: environment as unknown as PlatformControllerFlowAPI['environment'],
  });

  try {
    const isFirstInstall = options.firstInstall;
    const t = flowAPI.translations.t as TFunction;
    const fedopsLogger = new FedopsLogger(flowAPI.fedops);

    const isResponsive = options.origin.type === EditorType.Responsive;
    const isStudio = options.origin.subType === 'STUDIO';

    const resolution = await editorSDK.document.environment.screen.getScreenResolution();

    isFirstInstall && (await showInstallationProgressBar(editorSDK, t, isResponsive));

    biReporterService?.reportOloGenericDebugBiEvent({
      subjectType: BI_SUBJECT,
      value: {
        editorReady: 'first_event',
        isStudio,
        isFirstInstall,
        msid,
      },
    });

    if (isFirstInstall) {
      // eslint-disable-next-line no-console
      console.log('Online orders - first install starting...');
      fedopsLogger.restaurantsOrdersFirstInstallStarted();

      biReporterService?.reportOloGenericDebugBiEvent({
        subjectType: BI_SUBJECT,
        value: {
          editorReady: 'first_install_started',
          isStudio,
          isFirstInstall,
          msid,
        },
      });

      await progressBarMoveToStepNumber(editorSDK, t, 1, isResponsive);

      const oloPageRef = await createAppPage({
        editorSDK,
        appDefId,
        pageData: PAGE_DATA,
        isResponsive,
        isStudio,
        shouldAddMenuItem: true,
        t,
      });

      biReporterService?.reportOloGenericDebugBiEvent({
        subjectType: BI_SUBJECT,
        value: {
          editorReady: 'before_installing_lightboxes',
          oloPageRef,
          msid,
        },
      });

      // eslint-disable-next-line no-console
      console.log('Online orders - installing lightboxes...');
      await installLightboxes(editorSDK, appDefId, isResponsive, biReporterService);

      biReporterService?.reportOloGenericDebugBiEvent({
        subjectType: BI_SUBJECT,
        value: {
          editorReady: 'after_installing_lightboxes',
          msid,
        },
      });

      await progressBarMoveToStepNumber(editorSDK, t, 2, isResponsive);
      await installAppIfMissing(editorSDK, APP_DEF_IDS.orderTracker, biReporterService);
      await progressBarMoveToStepNumber(editorSDK, t, 3, isResponsive);
      await installAppIfMissing(editorSDK, APP_DEF_IDS.menus, biReporterService);
      await navigateToPage(editorSDK, PAGE_DATA.pageId);
      await installAppIfMissing(editorSDK, APP_DEF_IDS.tips, biReporterService);
      await progressBarMoveToStepNumber(editorSDK, t, 4, isResponsive);

      fedopsLogger.restaurantsOrdersFirstInstallEnded();
      // eslint-disable-next-line no-console
      console.log('Online orders - first install ended.');
      biReporterService?.reportOloGenericDebugBiEvent({
        subjectType: BI_SUBJECT,
        value: {
          editorReady: 'first_install_ended',
          isStudio,
          isFirstInstall,
          msid,
        },
      });
    } else {
      // TODO - tmp solution for existing sites
      const dishItemLightbox = await getComponentByTitle(editorSDK, DISH_ITEM_MODAL_TITLE);
      dishItemLightbox && updateWidgetLayout(editorSDK, dishItemLightbox);
    }

    await setOrdersPageState(editorSDK);

    disablePopupsAutoOpen(editorSDK);

    // eslint-disable-next-line no-console
    console.log('Online orders - panels logic start...');
    const panelsApi = await new PanelsApiFactory().createPanelsApi(editorSDK, options.origin.type);

    const openBlocksPanelWrapper = async (panelName: string, componentRef: ComponentRef) => {
      await panelsApi.openBlocksPanel(panelName, componentRef);
    };

    await editorSDK.addEventListener('widgetGfppClicked', async (event) => {
      const { id, componentRef } = event.detail;
      const isMobile = await isMobileViewport(editorSDK);

      switch (id) {
        case PANEL_IDS.oloElementsPanel: {
          fedopsLogger.openOloElementsPanelStarted();
          await openOloElementsPanel(editorSDK, componentRef, t as TFunction);
          fedopsLogger.openOloElementsPanelEnded();
          break;
        }
        case PANEL_IDS.manageMenus: {
          fedopsLogger.openManageMenusDashboardStarted();
          openManageMenuDashboardPanel(editorSDK);
          fedopsLogger.openManageMenusDashboardEnded();
          break;
        }
        case PANEL_IDS.ordersSettings: {
          fedopsLogger.openOrdersSettingsDashboardStarted();
          openOrdersSettingsDashboardPanel(editorSDK);
          fedopsLogger.openOrdersSettingsDashboardEnded();
          break;
        }
        case PANEL_IDS.itemModalElements: {
          fedopsLogger.openItemModalElementsPanelStarted();
          await openItemModalElementsPanel(editorSDK, componentRef, t as TFunction);
          fedopsLogger.openItemModalElementsPanelEnded();
          break;
        }
        case PANEL_IDS.itemLayout: {
          fedopsLogger.openItemLayoutPanelStarted();
          const panelName = isMobile ? PANEL_NAMES.itemLayoutMobile : PANEL_NAMES.itemLayout;
          openBlocksPanelWrapper(panelName, componentRef);
          fedopsLogger.openItemLayoutPanelEnded();
          break;
        }
        case PANEL_IDS.dishesElementsPanel: {
          fedopsLogger.openDishesElementsPanelStarted();
          await openDishesElementsPanel(editorSDK, componentRef, t as TFunction);
          fedopsLogger.openDishesElementsPanelEnded();
          break;
        }
      }
    });

    await editorSDK.addEventListener('componentGfppClicked', async (event) => {
      const { id, componentRef } = event.detail;
      const isMobile = await isMobileViewport(editorSDK);

      switch (id) {
        case PANEL_IDS.headerSettings: {
          fedopsLogger.openHeaderSettingsPanelStarted();
          openBlocksPanelWrapper(PANEL_NAMES.headerSettings, componentRef);
          fedopsLogger.openHeaderSettingsPanelEnded();
          break;
        }
        case PANEL_IDS.headerLayout: {
          fedopsLogger.openHeaderSettingsPanelStarted();
          const panelName = isMobile ? PANEL_NAMES.headerLayoutMobile : PANEL_NAMES.headerLayout;
          openBlocksPanelWrapper(panelName, componentRef);
          fedopsLogger.openHeaderSettingsPanelEnded();
          break;
        }
        case PANEL_IDS.imageLayout: {
          fedopsLogger.openHeaderSettingsPanelStarted();
          openBlocksPanelWrapper(PANEL_NAMES.imageLayout, componentRef);
          fedopsLogger.openHeaderSettingsPanelEnded();
          break;
        }
        case PANEL_IDS.headerElements: {
          fedopsLogger.openHeaderElementsPanelStarted();
          await openHeaderElementsPanel(editorSDK, componentRef, t as TFunction);
          fedopsLogger.openHeaderElementsPanelEnded();
          break;
        }
        case PANEL_IDS.itemLayout: {
          fedopsLogger.openItemLayoutPanelStarted();
          openBlocksPanelWrapper(PANEL_NAMES.itemLayout, componentRef);
          fedopsLogger.openItemLayoutPanelEnded();
          break;
        }
        case PANEL_IDS.manageMenus: {
          fedopsLogger.openManageMenusDashboardStarted();
          openManageMenuDashboardPanel(editorSDK);
          fedopsLogger.openManageMenusDashboardEnded();
          break;
        }
      }
    });
    // eslint-disable-next-line no-console
    console.log('Online orders - panels logic ended.');

    hideInstallationProgressBar(editorSDK, isResponsive);
  } catch (e: unknown) {
    biReporterService?.reportOloGenericDebugBiEvent({
      subjectType: BI_SUBJECT,
      value: {
        editorReady: 'catch',
        msid,
        e,
      },
    });

    // @ts-expect-error
    // eslint-disable-next-line no-console
    console.error('Online orders - installation error: ', e, e.message);
  }
};

/* eslint-disable @typescript-eslint/no-explicit-any */
import type { AppManifest, InitialAppData } from '@wix/platform-editor-sdk';

import { fetchWidgetsStageData } from '@wix/bob-widget-services';
import { setOloGfpp } from '../utils/utils';

export const createAppManifest = async (
  options: any,
  _editorSDK: any,
  _contextParams: any,
  _flowAPI: any
): Promise<AppManifest> => {
  const { appManifestBuilder, ...appData } = options;

  const baseManifest = await fetchWidgetsStageData(appData as InitialAppData);
  const appManifest = appManifestBuilder.withJsonManifest(baseManifest).build();

  setOloGfpp(appManifest);

  return appManifest;
};

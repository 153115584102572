import { APP_DEF_IDS } from '@wix/restaurants-consts';
import { MANAGE_MENUS_BM_URL, ORDERS_SETTINGS_BM_URL } from '../appConsts/consts';
import type { FlowEditorSDK } from '@wix/yoshi-flow-editor';

const openDashboardPanel = (editorSDK: FlowEditorSDK, appDefId: string, url: string) => {
  editorSDK.editor
    .openDashboardPanel(appDefId, {
      url,
      closeOtherPanels: false,
    })
    .then(async () => {
      await editorSDK.document.application.livePreview.refresh('', {
        shouldFetchData: true,
        source: '',
      });
      await editorSDK.document.application.reloadManifest();
    });
};

export const openManageMenuDashboardPanel = (editorSDK: FlowEditorSDK) => {
  openDashboardPanel(editorSDK, APP_DEF_IDS.menus, MANAGE_MENUS_BM_URL);
};

export const openOrdersSettingsDashboardPanel = (editorSDK: FlowEditorSDK) => {
  openDashboardPanel(editorSDK, APP_DEF_IDS.orders, ORDERS_SETTINGS_BM_URL);
};
